export const ALL_COUNTRY_NAME_CODE = {
    "AF": "Afghanistan",
    "AL": "Albanien",
    "DZ": "Algeriet",
    "VI": "Amerikanska Jungfruöarna",
    "AS": "Amerikanska Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarktis",
    "AG": "Antigua och Barbuda",
    "AR": "Argentina",
    "AM": "Armenien",
    "AW": "Aruba",
    "AU": "Australien",
    "AZ": "Azerbajdzjan",
    "AX": "Åland",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BE": "Belgien",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Plurinational State of Bolivia",
    "BA": "Bosnien och Hercegovina",
    "BW": "Botswana",
    "BV": "Bouvetön",
    "BR": "Brasilien",
    "VG": "Brittiska Jungfruöarna",
    "IO": "Brittiska territoriet i Indiska Oceanen",
    "BN": "Brunei",
    "BG": "Bulgarien",
    "BF": "Burkina Faso",
    "MM": "Burma",
    "BI": "Burundi",
    "KY": "Caymanöarna",
    "CF": "Centralafrikanska republiken",
    "CL": "Chile",
    "CO": "Colombia",
    "CK": "Cooköarna",
    "CR": "Costa Rica",
    "CY": "Cypern",
    "DK": "Danmark",
    "CD": "Demokratiska republiken Kongo",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominikanska republiken",
    "EC": "Ecuador",
    "EG": "Egypten",
    "GQ": "Ekvatorialguinea",
    "SV": "El Salvador",
    "CI": "Elfenbenskusten",
    "ER": "Eritrea",
    "EE": "Estland",
    "ET": "Etiopien",
    "FK": "Falklandsöarna",
    "FJ": "Fiji",
    "PH": "Filippinerna",
    "FI": "Finland",
    "FX": "France métropolitaine (Frankrike europeiska delen)",
    "FR": "Frankrike",
    "GF": "Franska Guyana",
    "PF": "Franska Polynesien",
    "TF": "Franska södra territorierna",
    "FO": "Färöarna",
    "AE": "Förenade Arabemiraten",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgien",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Grekland",
    "GD": "Grenada",
    "GL": "Grönland",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard- och McDonaldsöarna",
    "HN": "Honduras",
    "HK": "Hongkong",
    "IN": "Indien",
    "ID": "Indonesien",
    "IQ": "Irak",
    "IR": "Iran",
    "IE": "Irland",
    "IS": "Island",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italien",
    "JM": "Jamaica",
    "JP": "Japan",
    "YE": "Jemen",
    "JE": "Jersey",
    "JO": "Jordanien",
    "CX": "Julön",
    "KH": "Kambodja",
    "CM": "Kamerun",
    "CA": "Kanada",
    "CV": "Kap Verde",
    "KZ": "Kazakstan",
    "KE": "Kenya",
    "CN": "Kina",
    "KG": "Kirgizistan",
    "KI": "Kiribati",
    "0CC": "Kokosöarna",
    "KM": "Komorerna",
    "CG": "Kongo-Brazzaville",
    "HR": "Kroatien",
    "CU": "Kuba",
    "KW": "Kuwait",
    "LA": "Laos",
    "LS": "Lesotho",
    "LV": "Lettland",
    "LB": "Libanon",
    "LR": "Liberia",
    "LY": "Libyen",
    "LI": "Liechtenstein",
    "LT": "Litauen",
    "LU": "Luxemburg",
    "MO": "Macau",
    "MG": "Madagaskar",
    "MK": "Makedonien",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldiverna",
    "ML": "Mali",
    "MT": "Malta",
    "MA": "Marocko",
    "MH": "Marshallöarna",
    "MQ": "Martinique",
    "MR": "Mauretanien",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexiko",
    "FM": "Mikronesiska federationen",
    "MZ": "Moçambique",
    "MD": "Moldavien",
    "MC": "Monaco",
    "MN": "Mongoliet",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "NA": "Namibia",
    "NR": "Nauru",
    "NL": "Nederländerna",
    "AN": "Nederländska Antillerna",
    "NP": "Nepal",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "KP": "Nordkorea",
    "MP": "Nordmarianerna",
    "NF": "Norfolkön",
    "NO": "Norge",
    "NC": "Nya Kaledonien",
    "NZ": "Nya Zeeland",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PA": "Panama",
    "PG": "Papua Nya Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PN": "Pitcairnöarna",
    // "PO": "Polen",
    "PL": "Polen",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Rumänien",
    "RW": "Rwanda",
    "RU": "Ryssland",
    "KN": "Saint Kitts och Nevis",
    "LC": "Saint Lucia",
    "VC": "Saint Vincent och Grenadinerna",
    "BL": "Saint-Barthélemy",
    "PM": "Saint-Pierre och Miquelon",
    "SB": "Salomonöarna",
    "WS": "Samoa",
    "SM": "San Marino",
    "SH": "Sankta Helena",
    "ST": "São Tomé och Príncipe",
    "SA": "Saudiarabien",
    "CH": "Schweiz",
    "SN": "Senegal",
    "RS": "Serbien",
    "SC": "Seychellerna",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakien",
    "SI": "Slovenien",
    "SO": "Somalia",
    "ES": "Spanien",
    "LK": "Sri Lanka",
    "GB": "Storbritannien",
    "SD": "Sudan",
    "SR": "Surinam",
    "SJ": "Svalbard och Jan Mayen",
    "SE": "Sverige",
    "SZ": "Swaziland",
    "ZA": "Sydafrika",
    "KR": "Sydkorea",
    "SY": "Syrien",
    "TJ": "Tadzjikistan",
    "TW": "Taiwan",
    "TZ": "Tanzania",
    "TD": "Tchad",
    "TH": "Thailand",
    "CZ": "Tjeckien",
    "TG": "Togo",
    "TK": "Tokelauöarna",
    "TO": "Tonga",
    "TT": "Trinidad och Tobago",
    "TN": "Tunisien",
    "TR": "Turkiet",
    "TM": "Turkmenistan",
    "TC": "Turks- och Caicosöarna",
    "TV": "Tuvalu",
    "DE": "Tyskland",
    "UG": "Uganda",
    "UA": "Ukraina",
    "HU": "Ungern",
    "UY": "Uruguay",
    "US": "USA",
    "UM": "USA:s yttre öar",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Vatikanstaten",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "BY": "Vitryssland",
    "EH": "Västsahara",
    "WF": "Wallis- och Futunaöarna",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    // "AX": "Åland",
    "AT": "Österrike",
    "TL": "Östtimor",
}

export const CURRENCY = [
    {
      "key": "US",
      "name": "USD"
    },
    {
      "key": "CA",
      "name": "CAD"
    },
    {
      "key": "EU",
      "name": "EUR"
    },
    {
      "key": "AE",
      "name": "AED"
    },
    {
      "key": "AF",
      "name": "AFN"
    },
    {
      "key": "AL",
      "name": "ALL"
    },
    {
      "key": "AM",
      "name": "AMD"
    },
    {
      "key": "AR",
      "name": "ARS"
    },
    {
      "key": "AU",
      "name": "AUD"
    },
    {
      "key": "AZ",
      "name": "AZN"
    },
    {
      "key": "BA",
      "name": "BAM"
    },
    {
      "key": "BD",
      "name": "BDT"
    },
    {
      "key": "BG",
      "name": "BGN"
    },
    {
      "key": "BH",
      "name": "BHD"
    },
    {
      "key": "BI",
      "name": "BIF"
    },
    {
      "key": "BN",
      "name": "BND"
    },
    {
      "key": "BO",
      "name": "BOB"
    },
    {
      "key": "BR",
      "name": "BRL"
    },
    {
      "key": "BW",
      "name": "BWP"
    },
    {
      "key": "BY",
      "name": "BYN"
    },
    {
      "key": "BZ",
      "name": "BZD"
    },
    {
      "key": "CD",
      "name": "CDF"
    },
    {
      "key": "CH",
      "name": "CHF"
    },
    {
      "key": "CL",
      "name": "CLP"
    },
    {
      "key": "CN",
      "name": "CNY"
    },
    {
      "key": "CO",
      "name": "COP"
    },
    {
      "key": "CR",
      "name": "CRC"
    },
    {
      "key": "CV",
      "name": "CVE"
    },
    {
      "key": "CZ",
      "name": "CZK"
    },
    {
      "key": "DJ",
      "name": "DJF"
    },
    {
      "key": "DK",
      "name": "DKK"
    },
    {
      "key": "DO",
      "name": "DOP"
    },
    {
      "key": "DZ",
      "name": "DZD"
    },
    {
      "key": "EE",
      "name": "EEK"
    },
    {
      "key": "EG",
      "name": "EGP"
    },
    {
      "key": "ER",
      "name": "ERN"
    },
    {
      "key": "ET",
      "name": "ETB"
    },
    {
      "key": "GB",
      "name": "GBP"
    },
    {
      "key": "GE",
      "name": "GEL"
    },
    {
      "key": "GH",
      "name": "GHS"
    },
    {
      "key": "GN",
      "name": "GNF"
    },
    {
      "key": "GT",
      "name": "GTQ"
    },
    {
      "key": "HK",
      "name": "HKD"
    },
    {
      "key": "HN",
      "name": "HNL"
    },
    {
      "key": "HR",
      "name": "HRK"
    },
    {
      "key": "HU",
      "name": "HUF"
    },
    {
      "key": "ID",
      "name": "IDR"
    },
    {
      "key": "IL",
      "name": "ILS"
    },
    {
      "key": "IN",
      "name": "INR"
    },
    {
      "key": "IQ",
      "name": "IQD"
    },
    {
      "key": "IR",
      "name": "IRR"
    },
    {
      "key": "IS",
      "name": "ISK"
    },
    {
      "key": "JM",
      "name": "JMD"
    },
    {
      "key": "JO",
      "name": "JOD"
    },
    {
      "key": "JP",
      "name": "JPY"
    },
    {
      "key": "KE",
      "name": "KES"
    },
    {
      "key": "KH",
      "name": "KHR"
    },
    {
      "key": "KM",
      "name": "KMF"
    },
    {
      "key": "KR",
      "name": "KRW"
    },
    {
      "key": "KW",
      "name": "KWD"
    },
    {
      "key": "KZ",
      "name": "KZT"
    },
    {
      "key": "LB",
      "name": "LBP"
    },
    {
      "key": "LK",
      "name": "LKR"
    },
    {
      "key": "LT",
      "name": "LTL"
    },
    {
      "key": "LV",
      "name": "LVL"
    },
    {
      "key": "LY",
      "name": "LYD"
    },
    {
      "key": "MA",
      "name": "MAD"
    },
    {
      "key": "MD",
      "name": "MDL"
    },
    {
      "key": "MG",
      "name": "MGA"
    },
    {
      "key": "MK",
      "name": "MKD"
    },
    {
      "key": "MM",
      "name": "MMK"
    },
    {
      "key": "MO",
      "name": "MOP"
    },
    {
      "key": "MU",
      "name": "MUR"
    },
    {
      "key": "MX",
      "name": "MXN"
    },
    {
      "key": "MY",
      "name": "MYR"
    },
    {
      "key": "MZ",
      "name": "MZN"
    },
    {
      "key": "NA",
      "name": "NAD"
    },
    {
      "key": "NG",
      "name": "NGN"
    },
    {
      "key": "NI",
      "name": "NIO"
    },
    {
      "key": "NO",
      "name": "NOK"
    },
    {
      "key": "NP",
      "name": "NPR"
    },
    {
      "key": "NZ",
      "name": "NZD"
    },
    {
      "key": "OM",
      "name": "OMR"
    },
    {
      "key": "PA",
      "name": "PAB"
    },
    {
      "key": "PE",
      "name": "PEN"
    },
    {
      "key": "PH",
      "name": "PHP"
    },
    {
      "key": "PK",
      "name": "PKR"
    },
    {
      "key": "PL",
      "name": "PLN"
    },
    {
      "key": "PY",
      "name": "PYG"
    },
    {
      "key": "QA",
      "name": "QAR"
    },
    {
      "key": "RO",
      "name": "RON"
    },
    {
      "key": "RS",
      "name": "RSD"
    },
    {
      "key": "RU",
      "name": "RUB"
    },
    {
      "key": "RW",
      "name": "RWF"
    },
    {
      "key": "SA",
      "name": "SAR"
    },
    {
      "key": "SD",
      "name": "SDG"
    },
    {
      "key": "SE",
      "name": "SEK"
    },
    {
      "key": "SG",
      "name": "SGD"
    },
    {
      "key": "SO",
      "name": "SOS"
    },
    {
      "key": "SY",
      "name": "SYP"
    },
    {
      "key": "TH",
      "name": "THB"
    },
    {
      "key": "TN",
      "name": "TND"
    },
    {
      "key": "TO",
      "name": "TOP"
    },
    {
      "key": "TR",
      "name": "TRY"
    },
    {
      "key": "TT",
      "name": "TTD"
    },
    {
      "key": "TW",
      "name": "TWD"
    },
    {
      "key": "TZ",
      "name": "TZS"
    },
    {
      "key": "UA",
      "name": "UAH"
    },
    {
      "key": "UG",
      "name": "UGX"
    },
    {
      "key": "UY",
      "name": "UYU"
    },
    {
      "key": "UZ",
      "name": "UZS"
    },
    {
      "key": "VE",
      "name": "VEF"
    },
    {
      "key": "VN",
      "name": "VND"
    },
    {
      "key": "XA",
      "name": "XAF"
    },
    {
      "key": "XO",
      "name": "XOF"
    },
    {
      "key": "YE",
      "name": "YER"
    },
    {
      "key": "ZA",
      "name": "ZAR"
    },
    {
      "key": "ZM",
      "name": "ZMK"
    },
    {
      "key": "ZW",
      "name": "ZWL"
    }
]

export const getCurrency = (data) => {
    const filter = CURRENCY.filter(el => el.key === data)
    if (filter.length > 0) {
        return filter[0].name
    } else {
        return 'SEK'
    }
}

export const getCountries = () => {
    const countries = []
    Object.keys(ALL_COUNTRY_NAME_CODE).forEach(el => {
        const obj = {}
        obj.key = el
        obj.name = ALL_COUNTRY_NAME_CODE[el]
        countries.push(obj)
    })
    return countries
}

export default {
    getCountries,
    getCurrency
}